<template>
  <div class="">
    <div style="font-size: 25px;">我的成员</div>
    <el-divider></el-divider>
    <el-table :data="tableData"
              style="width: 100%" border
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">
      <el-table-column type="index" width="40"></el-table-column>

      <el-table-column label="手机号" prop="phone"></el-table-column>

      <el-table-column label="用户姓名" prop="realName"></el-table-column>

      <el-table-column label="检测记录查询">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <!-- <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联
            </el-button> -->
            <el-button slot="reference" type="info">查看</el-button>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column label="发起急诊">
        <template v-slot="scope4">
          <el-button @click="createUrgency(scope4.row)">提交</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="font-size: 25px;margin-top: 40px">我的急诊</div>
    <el-divider></el-divider>
    <el-table :data="urgencyData"
              style="width: 100%" border
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">

      <el-table-column label="用户姓名" prop="uname"></el-table-column>

      <el-table-column label="检测记录"><el-button type="info">查看</el-button></el-table-column>

      <el-table-column label="急诊状态">
        <template v-slot="scope5">
          <el-button v-if="scope5.row.status==1" type="success" disabled>已接受</el-button>
          <el-button v-if="scope5.row.status==2" type="danger" disabled>已拒绝</el-button>
          <el-button v-if="scope5.row.status==3&&scope5.row.userType=='个人'" type="primary" disabled>待接受</el-button>
          <el-button v-if="scope5.row.status==3&&scope5.row.userType=='医生'" type="primary">接受</el-button>
        </template>
      </el-table-column>

      <el-table-column label="发起时间" prop="creatTime"></el-table-column>

      <el-table-column label="创建诊室">
        <template v-slot="scope6">
          <el-button v-if="scope6.row.status==1" type="primary">立即创建</el-button>
          <el-button v-if="scope6.row.status!=1" type="primary" disabled>稍后创建</el-button>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      emergency_list: [],
      search: '',
      emergency_history: [],
      size: '',
      // psInfoVisible: false,
      tableData: [
      ],
      urgencyData:[
        {
          uname:"zhangyifan",
          status:1,
          userType:"医生",
          creatTime:"Wed Aug 21 2019 10:00:58 GMT+0800 (中国标准时间)",
        },
      ],
      userNameOrPhone: '',
    };
  },
//监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    createUrgency(e){
      console.log("看看这一行")
      console.log(e)
      this.$http({
        url: this.$http.adornUrl(""),
        method: "post",
        data: this.$http.adornData({
          uid: e.uid,
          userType:"医生",
          docid: e.docId,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看上传后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getUserList(){
      this.$http({
        url: this.$http.adornUrl("/doctor/doctorpersonaluserrelation/list"),
        method: "post",
        data: this.$http.adornData({
          docId: this.$store.state.doctor_station.docid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
          this.tableData = data.relationEntities
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    getECG(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoJump',
        query: {
          uid: uid,
        } })
    },
    getECGHolter(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        } })
    },
    get8ECGHolter(e){
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({ name: 'HistoryEightJump',
        query: {
          uid: uid,
        } })
    },

  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getUserList()
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>